import React from 'react'

import styled from 'styled-components'
import { grid } from 'styled-components-grid'

import Slider from 'react-slick'
import '@styles/slick.css'

import parameters from '@parameters'

import BannerHeader from '@components/banner-header'

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: parameters.breakpoints.lg,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: parameters.breakpoints.md,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: parameters.breakpoints.sm,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
}

const Carousel = ({
  className,
  backgroundColor,
  heading,
  subheading,
  items,
}) => {
  const slides = items.map(item => {
    return (
      <div key={item.label}>
        <img src={item.image.publicURL} alt={item.description} />
      </div>
    )
  })

  return (
    <section className={className}>
      <div>
        <BannerHeader title={heading} subtitle={subheading} />

        <Slider {...settings}>{slides}</Slider>
      </div>
    </section>
  )
}

export default styled(Carousel)`
  background: ${props => props.theme.colors.background(props.backgroundColor)};

  padding-top: ${props => props.theme.dimen.banner._.top};
  padding-bottom: ${props => props.theme.dimen.banner._.bottom};

  ${props => props.theme.media.md`
    padding-top: ${props => props.theme.dimen.banner.md.top};
    padding-bottom: ${props => props.theme.dimen.banner.md.bottom};
  `}

  ${props => props.theme.media.lg`
    padding-top: ${props => props.theme.dimen.banner.lg.top};
    padding-bottom: ${props => props.theme.dimen.banner.lg.bottom};
  `}

  color: ${props => props.theme.colors.foreground(props.textColor)};

  header {
    padding-bottom: ${props => props.theme.dimen.banner._.bottom};
  }

  > article,
  > div {
    ${grid({})}

    justify-content: space-between;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    padding-left: ${props => props.theme.dimen.main.x};
    padding-right: ${props => props.theme.dimen.main.x};

    ${props => props.theme.media.xl`
      padding-left: 0;
      padding-right: 0;
      width: ${props => props.theme.breakpoints.xl}px;
    `}
  }

  .slick-slider {
    width: 100%;

    .slick-list {
      .slick-track {
        .slick-slide {
          display: flex !important;
          justify-content: center;

          align-items: center;
          display: flex !important;

          outline: none;
          width: 100%;

          > div {
            box-sizing: content-box;
            width: 100%;

            > div {
              justify-content: center;
              align-items: center;

              height: ${props => props.theme.dimen.carousel.minImageHeight};
              ${props => props.theme.media.md`
                height: ${props => props.theme.dimen.carousel.maxImageHeight};
              `}
              display: flex!important;
            }
          }

          img {
            max-height: ${props => props.theme.dimen.carousel.minImageHeight};
            ${props => props.theme.media.md`
              max-height: ${props => props.theme.dimen.carousel.maxImageHeight};
            `}

            max-width: 100%;

            filter: grayscale(100%);

            transition: all 0.15s ease-in;

            &:hover {
              filter: grayscale(0%);
            }
          }
        }
      }
    }
  }
`
