import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Markdown } from 'react-markdown-tree'

import Layout from '@components/layout'

import BannerList from '@components/banner/list'
import LightMasthead from '@components/banner/light-masthead'
import Masthead from '@components/banner/masthead'
import LinkList from '@components/banner/link-list'
import Carousel from '@components/banner/carousel'

export default ({
  data: {
    allServicesYaml: { edges: links },
    page: {
      frontmatter: {
        keywords,
        title,
        masthead,
        previewImage,
        technologies,
        services,
        process,
      },
    },
    processImage,
  },
  location,
}) => {
  const image =
    (previewImage &&
      previewImage.childImageSharp &&
      previewImage.childImageSharp.general) ||
    undefined

  return (
    <Layout
      title={title}
      image={image}
      keywords={keywords}
      pathname={location.pathname}
    >
      <BannerList>
        {masthead && masthead.display && (
          <LightMasthead
            backgroundImage={masthead.backgroundImage}
            backgroundColor={masthead.colors.background}
            textColor={masthead.colors.text}
            markerColor={masthead.colors.marker}
            actionColors={masthead.colors.action}
            title={masthead.title}
            subtitle={masthead.subtitle}
            actionDisplay={masthead.action.display}
            actionLabel={masthead.action.label}
            actionTarget={masthead.action.target}
          >
            {masthead.text && <Markdown>{masthead.text}</Markdown>}
          </LightMasthead>
        )}
        {services && services.display && (
          <Masthead
            backgroundColor={
              services && services.colors && services.colors.background
            }
            markerColor={services && services.colors && services.colors.marker}
            textColor={services && services.colors && services.colors.text}
            title={services && services.heading}
            subtitle={services && services.subheading}
          >
            <LinkList
              textColor={services.colors.item.text.normal}
              texHoverColor={services.colors.item.text.hover}
              backgroundColor={services.colors.item.background.normal}
              backgroundHoverColor={services.colors.item.background.hover}
              items={links.map(link => ({
                title: link.node.name,
                link: link.node.url,
                image: link.node.image,
              }))}
            />
          </Masthead>
        )}
        {process && process.display && (
          <Masthead
            backgroundColor={
              process && process.colors && process.colors.background
            }
            markerColor={process && process.colors && process.colors.marker}
            textColor={process && process.colors && process.colors.text}
            title={process && process.heading}
            subtitle={process && process.subheading}
          >
            <Img fluid={processImage.childImageSharp.fluid} />
          </Masthead>
        )}
        {technologies && technologies.display && (
          <Carousel
            backgroundColor={technologies.colors.background}
            textColor={technologies.colors.text}
            heading={technologies.heading}
            subheading={technologies.subheading}
            items={technologies.items}
          />
        )}
      </BannerList>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allServicesYaml(filter: { url: { ne: $slug }, index: { eq: true } }) {
      edges {
        node {
          name
          url
          index
          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        keywords
        previewImage {
          childImageSharp {
            general: fixed(width: 1200, height: 1200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        masthead {
          display
        }
        services {
          colors {
            item {
              background {
                normal
                hover
              }
              text {
                normal
                hover
              }
            }
            background
            text
          }
          display
          heading
          subheading
        }
        process {
          colors {
            background
            text
          }
          display
          heading
          subheading
        }
        technologies {
          colors {
            background
            text
          }
          display
          heading
          subheading
          items {
            label
            description
            image {
              publicURL
            }
          }
        }
      }
      ...LightMastheadFragment
    }
    processImage: file(relativePath: { eq: "process.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
